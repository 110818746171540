exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-backup-consulting-js": () => import("./../../../src/pages/backup_consulting.js" /* webpackChunkName: "component---src-pages-backup-consulting-js" */),
  "component---src-pages-consulting-js": () => import("./../../../src/pages/consulting.js" /* webpackChunkName: "component---src-pages-consulting-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-acoustic-platform-js": () => import("./../../../src/pages/portfolio/acoustic-platform.js" /* webpackChunkName: "component---src-pages-portfolio-acoustic-platform-js" */),
  "component---src-pages-portfolio-clean-energy-js": () => import("./../../../src/pages/portfolio/clean-energy.js" /* webpackChunkName: "component---src-pages-portfolio-clean-energy-js" */),
  "component---src-pages-portfolio-cm-500-js": () => import("./../../../src/pages/portfolio/cm-500.js" /* webpackChunkName: "component---src-pages-portfolio-cm-500-js" */),
  "component---src-pages-portfolio-elvis-js": () => import("./../../../src/pages/portfolio/elvis.js" /* webpackChunkName: "component---src-pages-portfolio-elvis-js" */),
  "component---src-pages-portfolio-kupibilet-mobile-js": () => import("./../../../src/pages/portfolio/kupibilet-mobile.js" /* webpackChunkName: "component---src-pages-portfolio-kupibilet-mobile-js" */),
  "component---src-pages-portfolio-kupibilet-web-js": () => import("./../../../src/pages/portfolio/kupibilet-web.js" /* webpackChunkName: "component---src-pages-portfolio-kupibilet-web-js" */),
  "component---src-pages-portfolio-moika-mobile-js": () => import("./../../../src/pages/portfolio/moika-mobile.js" /* webpackChunkName: "component---src-pages-portfolio-moika-mobile-js" */),
  "component---src-pages-portfolio-omnivise-mobile-js": () => import("./../../../src/pages/portfolio/omnivise-mobile.js" /* webpackChunkName: "component---src-pages-portfolio-omnivise-mobile-js" */),
  "component---src-pages-portfolio-sipos-js": () => import("./../../../src/pages/portfolio/sipos.js" /* webpackChunkName: "component---src-pages-portfolio-sipos-js" */)
}

